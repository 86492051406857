<template>
  <el-main>
    <el-row class="body_img"></el-row>

    <el-row type="flex" justify="center">
      <el-col :span="17" class="bkg">
        <h1 class="big_title_fun">
          <img
            :src="url"
            style="margin-right: 10px; width: 40px"
            draggable="false"
          />{{ title }}
        </h1>
        <span style="color: #858690">{{ des }}</span>
        <el-upload
          class="upload-demo"
          auto-upload
          drag
          :show-file-list="false"
          :on-success="upload_file_success"
          :on-error="upload_file_error"
          :before-upload="loading"
          :data="appkey"
          action="https://svr.pdfgo.cc/updown/upload"
          :multiple="multiple"
          :accept="upload_type"
        >
          <i
            class="el-icon-upload"
            style="color: #435bfd; margin-top: 120px"
          ></i>
          <div class="el-upload__text">
            将文件拖到此处，或<br /><el-button>点我上传</el-button>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </div>
        </el-upload>
      </el-col>
    </el-row>




    <el-divider></el-divider>
    <div class="si">
   <div class="b" v-if="
this.work_type == 'pdf_to_word'"><h3 style="border-left:5px solid #409EFF">PDF转换Word的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF转换成Word
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF转换Word模式
</h3>
      <p class="func_desc">优化转换PDF转化Word乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>

  </li>
</div>

</ul>

</div>
</div>




 <div class="b" v-if="
this.work_type == 'pdf_to_excel'"><h3 style="border-left:5px solid #409EFF">PDF转Excel的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF转换成Excel
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF转换Excel模式
</h3>
      <p class="func_desc">优化转换PDF转化Excel乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>





 <div class="b" v-if="
this.work_type == 'pdf_to_ppt'"><h3 style="border-left:5px solid #409EFF">PDF转PPT的功能介绍</h3>

<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF转换成ppt
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF转换ppt模式
</h3>
      <p class="func_desc">优化转换PDF转化ppt乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>





<div class="b" v-if="
this.work_type == 'pdf_to_html'"><h3 style="border-left:5px solid #409EFF">PDF转Html的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF转换成html
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF转换html模式
</h3>
      <p class="func_desc">优化转换PDF转化html乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'pdf_to_img'"><h3 style="border-left:5px solid #409EFF">PDF转图片的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF转换成图片
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF转换图片模式
</h3>
      <p class="func_desc">优化转换PDF转化图片乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'pdf_to_txt'"><h3 style="border-left:5px solid #409EFF">PDF转TXT的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF转换成txt
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF转换txt模式
</h3>
      <p class="func_desc">优化转换PDF转化txt乱码，排版问题，为您提供优质的转换服务。</p>

    </div>


  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'word_to_pdf'"><h3 style="border-left:5px solid #409EFF">Word转PDF的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把Word转换成PDF
</h3>
      <p class="func_desc">点击按钮或者Word拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量Word转换PDF模式
</h3>
      <p class="func_desc">优化转换Word转化PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'excel_to_pdf'"><h3 style="border-left:5px solid #409EFF">Excel转PDF的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把Excel转换成PDF
</h3>
      <p class="func_desc">点击按钮或者Excel拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量Excel转换PDF模式
</h3>
      <p class="func_desc">优化转换Excel转化PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'ppt_to_pdf'"><h3 style="border-left:5px solid #409EFF">PPT转PDF的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PPT转换成PDF
</h3>
      <p class="func_desc">点击按钮或者PPT拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PPT转换PDF模式
</h3>
      <p class="func_desc">优化转换PPT转化PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'txt_to_pdf'"><h3 style="border-left:5px solid #409EFF">TXT转PDF的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把TXT转换成PDF
</h3>
      <p class="func_desc">点击按钮或者TXT拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量TXT转换PDF模式
</h3>
      <p class="func_desc">优化转换TXT转化PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'lock_pdf'"><h3 style="border-left:5px solid #409EFF">PDF加密的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何将PDF加密
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件加密完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF加密模式
</h3>
      <p class="func_desc">优化转换PDF加密乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'unlock_pdf'"><h3 style="border-left:5px solid #409EFF">PDF解密的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何将PDF解密
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF解密模式
</h3>
      <p class="func_desc">优化转换PDF解密乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'logo_pdf'"><h3 style="border-left:5px solid #409EFF">PDF加水印的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何将PDF加上水印
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF加水印模式
</h3>
      <p class="func_desc">优化转换PDF加水印乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'unlogo_pdf'"><h3 style="border-left:5px solid #409EFF">PDF去水印的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何将PDF去掉水印
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF去水印模式
</h3>
      <p class="func_desc">优化转换PDF去水印乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'reset_pdf'"><h3 style="border-left:5px solid #409EFF">PDF重置密码的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何将PDF密码重置
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量将PDF密码重置模式
</h3>
      <p class="func_desc">优化转换将PDF密码重置乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'split_pdf'"><h3 style="border-left:5px solid #409EFF">PDF拆分的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF拆分成多个文件
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量将PDF拆分模式
</h3>
      <p class="func_desc">优化转换PDF拆分乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'rotate_pdf'"><h3 style="border-left:5px solid #409EFF">PDF旋转的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF旋转
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，选择旋转方向，输入旋转角度，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF旋转模式
</h3>
      <p class="func_desc">优化转换旋转PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'pdf_replace_str'"><h3 style="border-left:5px solid #409EFF">PDF文字替换的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF中的文字替换
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，输入原文文字与想要替换的文字，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量替换PDF文字模式
</h3>
      <p class="func_desc">优化转换替换PDF文字乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>


<div class="b" v-if="
this.work_type == 'del_str_pdf'"><h3 style="border-left:5px solid #409EFF">PDF文字删除的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF中的文字删除
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，输入所需删除的文字，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量删除PDF中的文字模式
</h3>
      <p class="func_desc">优化转换删除PDF中的文字乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'pdf_extract_img'"><h3 style="border-left:5px solid #409EFF">PDF图片提取的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何将PDF中的图片提取出来
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量提取PDF中图片模式
</h3>
      <p class="func_desc">优化转换提取PDF中的图片乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>





<div class="b" v-if="
this.work_type == 'del_pdf'"><h3 style="border-left:5px solid #409EFF">PDF页面删除的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF的页面删除
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，输入所需删除的页码，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量删除PDF页面模式
</h3>
      <p class="func_desc">优化转换删除PDF页面乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'pages_pdf'"><h3 style="border-left:5px solid #409EFF">PDF添加页码的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何为PDF添加页码
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量添加PDF页码模式
</h3>
      <p class="func_desc">优化转换添加PDF页码乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'title_pdf'"><h3 style="border-left:5px solid #409EFF">PDF添加页眉的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何添加PDF页眉
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，输入想要添加的文字，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量添加PDF页眉模式
</h3>
      <p class="func_desc">优化转换添加PDF页眉乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'word_str_replace'"><h3 style="border-left:5px solid #409EFF">Word文字替换的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把Word中的文字替换
</h3>
      <p class="func_desc">点击按钮或者Word拉至拖放区，将文件上传至我们平台，输入原文文字与想要替换的文字，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量将Word文字替换模式
</h3>
      <p class="func_desc">优化转换Word文字替换乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'word_del_str'"><h3 style="border-left:5px solid #409EFF">Word文字删除的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把Word中的文字删除
</h3>
      <p class="func_desc">点击按钮或者Word拉至拖放区，将文件上传至我们平台，输入想要删除的文字，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量把Word中的文字删除模式
</h3>
      <p class="func_desc">优化转换把Word中的文字删除乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>


<!-- 从这里开始 -->
<div class="b" v-if="
this.work_type == 'zip_pdf'"><h3 style="border-left:5px solid #409EFF">PDF压缩的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF压缩成小体积
</h3>
      <p class="func_desc">点击按钮或者将PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量压缩PDF模式
</h3>
      <p class="func_desc">优化转换压缩PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>


<div class="b" v-if="
this.work_type == 'img_sumiao'"><h3 style="border-left:5px solid #409EFF">图片转素描的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把图片转为素描
</h3>
      <p class="func_desc">点击按钮或者图片拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量图片转换素描模式
</h3>
      <p class="func_desc">优化转换图片转化素描乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>



<div class="b" v-if="
this.work_type == 'img_dongman'"><h3 style="border-left:5px solid #409EFF">图片转漫画的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把图片转换成漫画
</h3>
      <p class="func_desc">点击按钮或者图片拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量图片转换漫画模式
</h3>
      <p class="func_desc">优化转换图片转化漫画乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'img_logo'"><h3 style="border-left:5px solid #409EFF">图片加水印的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把图片加水印
</h3>
      <p class="func_desc">点击按钮或者图片拉至拖放区，将文件上传至我们平台，选择水印颜色，输入水印文字，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量图片加水印模式
</h3>
      <p class="func_desc">优化转换图片加水印乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>
</div>
</div>
</div>






  </el-main>
</template>

<script>
import common from "./common";
import { Loading } from "element-ui";
import apiService from "@/api/service.js";
export default {
  name: "mybody",
  props: ["work_type"],
  mixins: [common],
  data() {
    return {
      loading_1: "1000",
      appkey: {
        appKey: "wxncvt90988",
      },
    };
  },
  methods: {
    loading(state) {
      this.loading_1 = Loading.service({});
    },
    upload_success_fun(response, fileList) {
      let fileName = fileList[0].response.upFile.split("/")[1]
      console.log(fileName)
      this.$router.push('/done/'+ this.work_type + '?file=' + fileName + '&name=' + fileList[0].name)
    },
    upload_file_success(response, file, fileList) {
      this.loading_1.close();
      if (response.code == 200) {
        this.upload_success_fun(response, fileList);
      }
      if (response.code == 400) {
        this.$message.error({
          message: response.msg,
          center: true,
          offset: 120,
        });
        console.log("上传失败");
      }
    },
    upload_file_error(response, file, fileList) {
      this.loading_1.close();
      this.$message.error({
        message: "上传失败",
        center: true,
        offset: 120,
      });
      console.log("上传失败");
    },
  },
};
</script>

<style scoped>
.el-main {
  background: #f1f3fe;
  color: #333;
  margin-top: 72px;
}

.body_img {
  height: 130px;
  background: url("~@/assets/content.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.bkg {
  color: #5a5b64;
  text-align: center;
  margin-top: -4px;
  height: 800px;
  background: white;
  border-radius: 20px;
}

.big_title_fun {
  font-size: 32px;
  padding-top: 36px;
  padding-bottom: 12px;
}

.upload-demo {
  /* width: 1000px; */
}

.el-button {
  height: 40px;
  width: 190px;
  margin-top: 15px;
  background: #435bfd;
  color: white;
}
.el-button:hover {
  background: #7083ff;
 
    /* border: px dashed #b6bfe1; */
}

.el-upload__text {
  font-size: 15px;
  padding: 20px;
}

/deep/ .el-upload-dragger {
  margin-top: 40px;
  min-height: 500px;
  min-width: 800px;
  background: linear-gradient(
180deg
,#f9fbff,#f2f7fe);
 /* border: px dashed #b6bfe1; */
}
/* .b{
  width: 100px;
  height: 100px;
  border: red 1px solid;
} */

.lu {
  height: 300px;
  width: 300px;
  /* border: 1px solid red; */
  float: left;
  border-radius: 20px;
  text-align: center;
}

.si {
  width: 1000px;
  /* border: 1px solid red; */
  border-radius: 20px;
  /* text-align: center; */
  margin: 0 auto;
}

.b {
  width: 90%;
  /* border: 1px solid red; */
  /* margin-left: 100px; */
}
/* 文字说明 */
.func_list {
  margin: 22px -138px 0 0;
}
ul {
  list-style: none;
}

.g_ul > li {
  float: left;
}

.func_item {
  width: 238px;
  height: 235px;
  margin: 34px 138px 0 0;
  text-align: center;
  padding-left: 10px;
}

.func_list .img {
  height: 80px;
}

.func_title {
  margin-top: 15px;
  font-size: 18px;
  line-height: 30px;
  color: #333;
}
.func_desc {
  margin-top: 7px;
  font-size: 14px;
  line-height: 24px;
  color: #656565;
}

</style>